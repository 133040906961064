import { gql } from '../generated';

gql(`
  fragment BankLink on BankLink {
    id
    status
    linkedAccounts {
      ...LinkedAccount
    }
  }
`);

gql(`
  fragment LinkedAccount on LinkedAccount {
    id
    accountMask
    name
    routingNumber
    subtype
  }
`);

export const GET_BANK_LINKS = gql(`
  query GetBankLinks($customerID: ID!) {
    customer(id: $customerID) {
      id
      bankLinks {
        ...BankLink
      }
    }
  }
`);

export const GET_BANK_LINK = gql(`
  query GetBankLink($bankLinkID: ID!) {
    bankLink(id: $bankLinkID) {
      ...BankLink
    }
  }
`);
