import React from 'react';
import { useQuery, gql } from '@apollo/client';
import FlatList from 'flatlist-react';

import { makeName, getSourceLogo } from 'src/web/utility';

import { Scroll, Toolbar, TaskRow } from 'src/web/components';

const MANUAL_ID_QUERY = gql`
  query HealthLinkUsers($input: SearchPersonReferenceRequest!) {
    searchPersonReference(input: $input) {
      pageInfo {
        hasNextPage
        currentPageNumber
        pageSize
        totalItems
      }
      edges {
        id

        personReference {
          id
          workType
          savingsKYC
          healthApplicantEmail
          healthApplicantGivenName
          healthApplicantFamilyName
          givenName
          familyName
          phoneNumber
          email
          avatarImage
          signupCode
          signupContext
          ipAddress
          isDeleted
        }
      }
    }
  }
`;

const POLL_FREQ = 50000;

const HealthLinkPage = ({ selectedId, setSelected }) => {
  const { data, loading, error } = useQuery(MANUAL_ID_QUERY, {
    pollInterval: POLL_FREQ,
    variables: {
      id: null,
      input: {
        identityProviders: ['CMS'],
        identityStatuses: ['VERIFIED'],
        pagination: {
          pageNumber: 1,
          pageSize: 250,
        },
      },
    },
  });
  const results = data?.searchPersonReference?.edges;

  return (
    <>
      <Toolbar title="Health Link Users" subtitle="EDE" icon="Fingerprint" overInspector />
      <Scroll toolbar>
        <FlatList
          renderWhenEmpty={() => <></>}
          list={results}
          renderItem={(person, idx) => {
            const p = {
              ...person?.personReference,
              ...person?.person,
              __typename: 'PersonReference',
            };
            return (
              <TaskRow
                standalone
                large
                selected={selectedId === person?.id}
                defaultName={p?.phoneNumber || 'No Name'}
                showAvatar
                onPress={() => setSelected(person?.id, { user: person?.id })}
                user={p}
                key={person?.id}
                detail={p?.email}
                rightIcon={getSourceLogo(p?.signupCode)}
                name={makeName(p)}
              />
            );
          }}
        />
      </Scroll>
    </>
  );
};

export default HealthLinkPage;
