/**
 * Use this whenever we want to show a dropdown of set of years that we currently care about
 */
export const SUPPORTED_COVERAGE_YEARS = [2025, 2024];

/**
 * Use this as the default for form values, etc.
 */
export const DEFAULT_COVERAGE_YEAR = 2025;

export * from './options';
