import React from 'react';

import { useUserSummary } from 'src/web/hooks';
import { IRow, MenuDivider, Scroll } from 'src/web/components';
import { useEdit } from 'src/components/useEdit';
import { PARTNERS } from 'src/web/data';
import { useContent } from 'src/web/hooks';
import { useMutation } from '@apollo/client';
import { FilingStatus, SignupContext, UPDATE_USER } from 'src/data';
import { propercase } from 'src/_utils/format';
import { SendBrokerConsent } from 'src/components/users';
import { useModal } from 'src/util/modals';
import { Env } from 'src/_utils';
import { DateFormat } from 'src/utils';

export function HomePage({ userId, onRename }) {
  const { edit } = useEdit();
  const { openModal } = useModal();

  const {
    loading,
    joinDate,
    state,
    email,
    phone,
    isLocked,
    familyName,
    givenName,
    nickname,
    middleName,
    zip,
    filingStatus,
    joinCode,
    dob,
    signupContext,
    ipAddress,
    brokerConsent,
    consentRequest,
    partnerParam,
  } = useUserSummary({
    id: userId,
  });

  const { data } = useContent(PARTNERS, {});

  const [update, { loading: updating }] = useMutation(UPDATE_USER);

  const handleEdit = () => {
    edit({
      title: 'Add Signup Code',
      initialValues: {
        signupCode: joinCode,
        signupContext: signupContext === 'ONBOARDING' ? '' : signupContext,
      },
      fields: [
        {
          type: 'dropdown',
          placeholder: 'Select signup code',
          name: 'signupCode',
          label: 'Signup code',
          options: data?.partners?.map(partner => ({
            label: `${partner.name} (${partner.code})`,
            value: partner.code,
          })),
        },
        {
          type: 'dropdown',
          placeholder: 'Select signup context',
          name: 'signupContext',
          label: 'Signup Context',
          options: Object.values(SignupContext).map(context => ({
            label: propercase(context.replaceAll('_', ' ')),
            value: context,
          })),
        },
      ],
      onSubmit: vals => {
        update({
          variables: {
            input: {
              signupCode: vals?.signupCode,
              signupContext: vals?.signupContext || null,
            },
            personID: userId,
          },
        });
      },
    });
  };

  const editDetails = () => {
    edit({
      title: 'Edit User Details',
      initialValues: {
        dob,
        filingStatus,
      },
      fields: [
        { type: 'date', name: 'dob', label: 'Date of Birth' },
        {
          type: 'dropdown',
          placeholder: 'Select filing status',
          name: 'filingStatus',
          label: 'Filing Status',
          options: [
            { label: 'Single', value: FilingStatus.Single },
            { label: 'Married Filing Jointly', value: FilingStatus.Married },
            { label: 'Married Filing Separately', value: FilingStatus.MarriedSeparately },
            { label: 'Head of Household', value: FilingStatus.Head },
          ],
        },
      ],
      onSubmit: vals => {
        update({
          variables: {
            input: {
              dob: vals.dob,
              filingStatus: vals.filingStatus,
            },
            personID: userId,
          },
        });
      },
    });
  };

  return (
    <Scroll loading={loading || updating}>
      <IRow noMax label="User ID" type="mono" value={userId} />
      <IRow noMax label="Status" value={isLocked ? 'LOCKED' : 'UNLOCKED'} type="status" hideIcon />
      <MenuDivider invert />
      <IRow noMax label="Email" value={email} />
      <IRow noMax label="Phone" value={phone} />

      <MenuDivider invert />
      <IRow noMax label="Preferred Name" value={nickname} onPress={onRename} actionType="edit" />
      <IRow noMax label="First Name" value={givenName} onPress={onRename} actionType="edit" />
      <IRow noMax label="Middle Name" value={middleName} onPress={onRename} actionType="edit" />
      <IRow noMax label="Last Name" value={familyName} onPress={onRename} actionType="edit" />
      <MenuDivider invert />

      <IRow noMax label="State" value={state} />
      <IRow noMax label="ZIP Code" value={zip} />
      <IRow
        noMax
        label="Filing Status"
        value={filingStatus?.toProperCase()}
        onPress={editDetails}
        actionType="edit"
      />
      <IRow noMax label="Date of Birth" type="dateOnly" value={dob} onPress={editDetails} actionType="edit" />
      <MenuDivider invert />

      <IRow noMax label="Signup Date" type="date" value={joinDate} format={DateFormat.LONG} />
      <IRow noMax label="Signup Code" type="mono" value={joinCode} onPress={handleEdit} actionType="edit" />
      <IRow noMax label="Partner Param" type="mono" value={partnerParam} />
      <IRow
        noMax
        label="Signup Context"
        type="enum"
        value={signupContext}
        onPress={handleEdit}
        actionType="edit"
      />
      <IRow noMax label="IP Address" type="mono" value={ipAddress} />
      <MenuDivider invert />

      {brokerConsent ? (
        <>
          <IRow noMax label="Broker Consent" type="bool" value={brokerConsent} />
          {consentRequest?.agreedAt && (
            <IRow
              noMax
              label="Agreement Date"
              type="date"
              value={consentRequest.agreedAt}
              format={DateFormat.LONG}
            />
          )}
        </>
      ) : (
        <>
          {consentRequest ? (
            <>
              <IRow noMax label="Consent Status" type="enum" value={consentRequest.status} />
              <IRow
                noMax
                label="Consent Link"
                type="link"
                value={`${Env.appUrl}/auth/consent?token=${consentRequest?.token}`}
              />
              <IRow
                noMax
                label="Valid Until"
                type="date"
                value={consentRequest.expiresAt}
                format={DateFormat.LONG}
              />
            </>
          ) : (
            <IRow
              noMax
              label="Broker Consent"
              type="bool"
              value={brokerConsent}
              onPress={() => openModal(<SendBrokerConsent userID={userId} />)}
              actionType="plus"
            />
          )}
        </>
      )}
    </Scroll>
  );
}
