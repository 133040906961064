import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Priority, useRegisterActions } from 'kbar';

import { EDE_ROUTES, groupBy, STATES } from 'src/web/utility';
import { HealthEnrollment, RowGroup, Scroll, Spinner } from 'src/web/components';
import { HEALTH_LINKS, HEALTH_NEW, HEALTH_NEW_POLS } from 'src/web/data';

import { lookupApplications, syncHealth } from 'src/web/data/actions/user';
import { KACTIONS } from 'src/web/kbar';
import { makeLabelIcons } from 'src/web/utility/tasks';
import { SelectedType, useInspector } from 'src/hooks/useInspector';
import { Select } from 'antd';

const ISSUE_STATUS = {
  pending: ['UNDER_REVIEW', 'CONTACTING_EMPLOYER_BECAUSE_SUBJECT_TO_ESC_SAMPLING', 'PROCESSING'],
  actionNeeded: ['PENDING_DOCUMENTATION', 'PENDING_ADDITIONAL_DOCUMENTATION', 'ACTION_NEEDED'],
  resolved: ['RESOLVED', 'COMPLETE'],
};

const bucketIssues = ({ dmis, svis }) => {
  if (!dmis && !svis) return [];
  const issues = [...(dmis || []), ...(svis || [])];
  return {
    pending: issues?.filter((item, idx) => {
      return ISSUE_STATUS.pending.includes(item.status);
    }),
    actionNeeded: issues?.filter((item, idx) => {
      return ISSUE_STATUS.actionNeeded.includes(item.status);
    }),
    resolved: issues?.filter((item, idx) => {
      return ISSUE_STATUS.resolved.includes(item.status);
    }),
  };
};

export function HealthPage({ userId, inInspector }) {
  const { selectedId, setSelected } = useInspector();

  const { data: newData, loading, error } = useQuery(HEALTH_NEW, {
    skip: !userId,
    variables: {
      id: userId,
      input: {
        includeDeleted: true,
      },
    },
  });

  const { data: newPolData } = useQuery(HEALTH_NEW_POLS, {
    skip: !userId,
    variables: {
      id: userId,
      input2: {},
    },
  });

  const { data: linkData } = useQuery(HEALTH_LINKS, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });

  useRegisterActions(
    [
      {
        ...KACTIONS.HEALTH_SYNC,
        perform: () => syncHealth({ userId: userId, forceLink: true, forcePermissions: true }),
        priority: Priority.HIGH,
      },
      {
        ...KACTIONS.HEALTH_IMPORT,
        perform: () => lookupApplications({ userId: userId }),
        priority: Priority.HIGH,
      },
    ],
    [userId],
  );


  const links = [...(linkData?.viewerTwo?.existingApplicationInfo?.existingApplications || [])];

  const years = [2025, 2024, 2023, 2022];
  const apps = groupBy(
    newData?.viewerTwo?.health?.applications
      ?.map(a => ({ progress: EDE_ROUTES.indexOf(a.lastUsedRoute), ...a }))
      ?.sort((a, b) => b.progress - a.progress),
    'coverageYearNumber',
  );
  const pols = groupBy(
    newPolData?.viewerTwo?.health?.policies?.map(p => ({
      coverageYearNumber: p?.startDate?.slice(0, 4),
      ...p,
    })),
    'coverageYearNumber',
  );

  return (
    <Scroll transparent={inInspector} loading={loading}>
      {!newData && !newPolData && !!loading ? (
        <Spinner />
      ) : Object.keys(apps)?.length === 0 && pols?.length === 0 && links?.length === 0 ? (
        <div className="full">{error ? error?.toString : 'Nothing'}</div>
      ) : (
        <>
          {years?.map(
            year =>
              (apps[year] || pols[year]) && (
                <RowGroup key={year} title={year} close={year < 2021}>
                  {pols?.[year]
                    ?.sort((a, b) => Date.parse(b?.startDate) - Date.parse(a?.startDate))
                    ?.sort((a, b) => (b?.isDeleted || b?.policyStatus === 'CANCELLED' ? -1 : 1))
                    ?.map(pol => (
                      <HealthEnrollment
                        compact={inInspector}
                        key={pol.id}
                        isDeleted={pol?.isDeleted}
                        type={
                          /dental/.test(pol?.providerPlan?.productDivision?.toLowerCase())
                            ? 'DENTAL'
                            : 'HEALTH'
                        }
                        name={pol?.planName || pol?.providerPlan?.name}
                        selected={selectedId === pol?.id}
                        onPress={() => setSelected(pol?.id, { type: SelectedType.POLICY })}
                        planYear={pol?.coverageYearNumber}
                        status={pol?.policyStatus?.replace('ACTIVE_PENDING_PAYMENT', 'PENDING_PAYMENT')}
                        startDate={pol?.startDate}
                        endDate={pol?.endDate}
                        origin=""
                        pathwayType=""
                        labels={[
                          pol?.isDeleted && 'DELETED',
                          pol?.exchangePolicyNumber,
                          !!pol.agentID
                            ? ( pol?.agentID === '20655395' || pol?.agentID === '18946575' )  // TODO remove this after OE for PY24
                              ? '✓ NPN'
                              : pol?.agentID === '17068737'
                              ? 'Stride'
                              : '✕ NPN'
                            : 'No NPN',
                        ]}
                        issuer={pol?.providerPlan?.issuer?.name}
                        grossPremium={pol?.premium}
                        netPremium={pol?.premiumEffective}
                      />
                    ))}
                  <RowGroup subtle title="Applications" borderless>
                    {apps?.[year]
                      ?.sort((a, b) => (b?.isDeleted ? -1 : 1))
                      ?.map(app => (
                        <HealthEnrollment
                          isApplication
                          labelIcons={makeLabelIcons(app)}
                          compact={inInspector}
                          key={app.id}
                          id={app?.insuranceApplicationIdentifier}
                          type={`${STATES[app.coverageState]}`}
                          state={app.coverageState}
                          isDeleted={app?.isDeleted}
                          labels={[
                            app?.isDeleted && 'DELETED',
                            app.applicationContext || undefined,
                            app.insuranceApplicationIdentifier &&
                              `${app.insuranceApplicationIdentifier}${
                                app.applicationVersionNum ? ' v' + app.applicationVersionNum : ''
                              }`,
                          ]}
                          aptc={app?.isEligibilityDetermined ? app?.aptcHouseholdAmount : '––.––'}
                          selected={selectedId === app?.id}
                          status={app.status}
                          onPress={() => setSelected(app?.id, { type: SelectedType.APP })}
                          planYear={app?.coverageYearNumber}
                          pathwayType={app?.pathwayType}
                          origin={app?.applicationOrigin}
                          route={app?.lastUsedRoute}
                          isImport={app?.applicationOrigin === 'IMPORT'}
                          isLink={app?.applicationOrigin === 'LINK_STRICT'}
                          issues={bucketIssues({
                            dmis: app?.dmis,
                            svis: app?.svis,
                          })}
                        />
                      ))}
                  </RowGroup>
                </RowGroup>
              ),
          )}
          <RowGroup close title="Fastlink" subtle>
            {links
              ?.sort((a, b) => b?.coverageYearNumber - a?.coverageYearNumber)
              ?.map((item, idx) => (
                <HealthEnrollment
                  key={item.id || idx}
                  type="Application"
                  selected={selectedId === item?.id}
                  onPress={() => setSelected(item?.id, { type: SelectedType.LINK })}
                  planYear={item?.coverageYearNumber}
                  pathwayType="EDE"
                  status={item?.applicationStatus}
                  origin="Unlinked"
                />
              ))}
          </RowGroup>
        </>
      )}
    </Scroll>
  );
};
