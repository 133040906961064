import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';
import { initials, makeName } from 'src/web/utility';
import { Scroll, Header, IRow } from 'src/web/components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 99999999999999;
`;

export const ModalOverlayInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  background: var(--veil);
  z-index: 99999999999;
`;

const Modal = styled.div`
  position: absolute;
  right: var(--padding);
  top: var(--padding);
  bottom: var(--padding);
  left: var(--padding);
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  z-index: 99999999999;
  max-width: 1024px;
  max-height: ${p => (p.tall ? 1280 : 820)}px;
  margin: 0 0 0 auto;
  box-sizing: border-box;
  h1 {
    color: var(--fg);
  }
`;

const Body = styled.div`
  position: absolute;
  right: 0;
  left: var(--inspector);
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  flex: 0;
`;

const Sidebar = styled.div`
  position: absolute;
  left: 0;
  width: var(--inspector);
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  flex: 0;
`;

export interface FullModalProps {
  isOpen?: boolean;
  onRequestClose: () => void;
  title: string;
  subtitle?: string;
  hasChange?: boolean;
  task?: any;
  sidebarItem?: React.ReactNode;
  tall?: boolean;
  children: React.ReactNode;
}

export default ({
  onRequestClose,
  isOpen = true,
  children,
  title,
  subtitle,
  hasChange,
  task,
  sidebarItem,
  tall,
}: FullModalProps) => {
  const close = e => {
    if (hasChange) {
      if (window.confirm('discard edits?')) onRequestClose(e);
      else console.log('nah');
    } else {
      onRequestClose(e);
    }
  };
  useHotkeys('esc', close);
  const el = (
    <ModalOverlay className="full-overlay" onClick={close}>
      <ModalOverlayInner>
        <Modal
          tall={tall}
          className=" modal full-modal"
          onClick={e => {
            e.stopPropagation();
            return false;
          }}
        >
          <Sidebar>
            <Scroll padded sidebarColor>
              <Header title={title} subtitle={subtitle}>
                <IRow hideUnset label="Last Update" type="date" value={task?.lastUpdated} />

                {!!task && (
                  <>
                    <IRow
                      id={task?.id}
                      label="Assignee"
                      value={makeName(task?.assignee)}
                      avatar={task?.assignee?.avatarImage}
                      initials={initials(task?.assignee)}
                      actionType="menu"
                      type="assignee"
                    />
                    <IRow
                      id={task?.id}
                      label="Task Status"
                      type="status"
                      value={task?.status}
                      actionType="menu"
                    />
                  </>
                )}
              </Header>

              {sidebarItem}
            </Scroll>
          </Sidebar>
          <Body>
            <Scroll padded inspectorColor>
              {children}
            </Scroll>
          </Body>
        </Modal>
      </ModalOverlayInner>
    </ModalOverlay>
  );
  return isOpen ? ReactDOM.createPortal(el, document.getElementById('modal-root')) : null;
};
