import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import { makeName, makeAddress } from 'src/web/utility';
import { useUserSummary } from 'src/web/hooks';
import {
  Scroll,
  IRow,
  RowGroup,
  Input,
  InputLabel,
  StyledBottomBar,
  Button,
  Banner,
  FullModal,
} from 'src/web/components';

const LOCK = gql`
  mutation LockUserNew($input: lockUserInput!) {
    lockUser(input: $input)
  }
`;

/**
 * @deprecated
 * @todo fold into UpdateTierModal
 */
export const UnlockModal = ({ open, onRequestClose, userId, notes, setLockIsOpen, setNotes }) => {
  const { isLocked, preventIdentityLock, notes: userNotes } = useUserSummary({
    id: userId,
  });

  // @todo: lookup IDV infor
  const data = {};

  // @tod/o: add back lock functionality
  const [lock] = useMutation(LOCK, {
    refetchQueries: ['SessionInputs2', 'Activity'],
    variables: {
      input: {
        action: isLocked ? 'UNLOCK' : 'LOCK',
        lockedReason: notes,
        notes: notes,
        personID: userId,
        preventIdentityLock: null,
      },
    },
  });

  const viewer = { ...(data?.viewer || {}) };
  const user = { ...(viewer?.user || {}) };

  const banks = viewer?.bankLinks;

  return (
    <FullModal
      open={open}
      title="Lock/Unlock"
      onRequestClose={onRequestClose}
      sidebarItem={
        <>
          <RowGroup borderless>
            <IRow hideUnset pretext="DDA" label="Legal Name" pii value={makeName(user)} />

            {banks?.map((bank, idx) => (
              <IRow
                hideUnset
                pretext="Plaid"
                label="Legal Name"
                helpText="Plaid ID check result"
                value={bank?.identityPayload?.[0]?.names?.[0]}
              />
            ))}

            <IRow hideUnset pretext="Email" label="Email" pii value={user?.email} />
            {banks?.map((bank, idx) => (
              <IRow
                hideUnset
                pretext="Plaid"
                label="Plaid Email"
                helpText="Plaid ID check result"
                value={bank?.identityPayload?.[0]?.emails?.[0]}
              />
            ))}
            <IRow hideUnset pretext="DDA" label="Address" value={makeAddress(viewer?.legalAddress)} />

            {banks?.map((bank, idx) => (
              <IRow
                hideUnset
                pretext="Plaid"
                label="Plaid Address"
                helpText="Plaid ID check result"
                value={bank?.identityPayload?.[0]?.addresses?.[0]}
              />
            ))}
          </RowGroup>
        </>
      }
    >
      <Scroll transparent>
        <Banner
          title="Review all user information first"
          subtitle={!!userNotes && `Last note: "${userNotes}"`}
        />
        <div className="full">FYI: This will soon be combined with the tier-changer</div>
        <br />
      </Scroll>
      <StyledBottomBar bottom flexible>
        <InputLabel required>Notes</InputLabel>
        <Input
          autoFocus
          onChange={event => setNotes(event.target.value)}
          type="text"
          placeholder="Explain why you're taking this action"
          value={notes}
          style={{ marginBottom: 12 }}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              lock({
                variables: {
                  input: {
                    action: isLocked ? 'UNLOCK' : 'LOCK',
                    lockedReason: notes,
                    notes: notes,
                    personID: userId,
                  },
                },
              });
              setLockIsOpen(false);
            }
          }}
        />
        <Button
          style={{ marginBottom: 8 }}
          shadow
          fullWidth
          loading={false}
          color="page"
          background={isLocked ? 'var(--c-good-0)' : 'var(--c-bad-0)'}
          type="default"
          disabled={!notes}
          onPress={() => {
            lock({
              variables: {
                input: {
                  action: isLocked ? 'UNLOCK' : 'LOCK',
                  lockedReason: notes,
                  notes: notes,
                  personID: userId,
                },
              },
            });
            setLockIsOpen(false);
          }}
        >
          {isLocked ? 'Unlock' : 'Lock'}
        </Button>

        <Button
          fullWidth
          loading={false}
          type="subtle"
          background={isLocked ? 'var(--c-good-1)' : undefined}
          disabled={!notes}
          onPress={() => {
            lock({
              variables: {
                input: {
                  action: isLocked ? 'UNLOCK' : 'LOCK',
                  lockedReason: notes,
                  notes: notes,
                  personID: userId,
                  preventIdentityLock: !preventIdentityLock,
                },
              },
            });
            setLockIsOpen(false);
          }}
        >
          {isLocked
            ? 'Unlock and stop auto locking'
            : !!preventIdentityLock
            ? 'Enable auto locking'
            : 'Disable auto locking'}
        </Button>
      </StyledBottomBar>
    </FullModal>
  );
};

export default UnlockModal;
