import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { groupBy } from 'src/web/utility';
import { HealthEnrollment, IAction, MenuDivider, RowGroup } from 'src/web/components';
import { SelectedType, useInspector } from 'src/hooks/useInspector';
import { USER_POLICIES } from 'src/data';
import { Scroll } from 'src/kit';
import { useModal, ModalType } from 'src/hooks/useModal';

const years = [2025, 2024, 2023, 2022];

export function PoliciesPage({ userId, inInspector }) {
  const { selectedId, setSelected } = useInspector();
    const { openModal } = useModal();

  const { loading, data } = useQuery(USER_POLICIES, {
    variables: { id: userId },
    skip: !userId,
  });

  const policies = useMemo(() => {
    const formattedPolicies = (data?.viewerTwo?.health?.policies || [])?.map(p => ({
      coverageYearNumber: p?.startDate?.slice(0, 4),
      ...p,
    }))

    return groupBy(formattedPolicies, 'coverageYearNumber');
  }, [data]);

  return (
    <Scroll loading={loading} transparent={inInspector}>
      <IAction
        disabled={false}
        icon="Add"
        title="Create policy"
        onPress={() => openModal(ModalType.CREATE_POLICY, { userId })}
      />
      <MenuDivider invert />
      {Object.keys(policies).length === 0 && (
        <div className="full">No policies</div>
      )}

      {years.map(year => {
        if (!policies[year] || policies[year].length === 0) {
          return null;
        }

        return (
          <RowGroup key={year} title={year} close={year < 2021} borderless>
            {policies[year].map(policy => (
              <HealthEnrollment
                key={policy.id}
                compact={inInspector}
                isDeleted={policy?.isDeleted}
                type={
                  /dental/.test(policy?.providerPlan?.productDivision?.toLowerCase())
                    ? 'DENTAL'
                    : 'HEALTH'
                }
                name={policy?.planName || policy?.providerPlan?.name}
                selected={selectedId === policy?.id}
                onPress={() => setSelected(policy?.id, { type: SelectedType.POLICY })}
                planYear={policy?.coverageYearNumber}
                status={policy?.policyStatus?.replace('ACTIVE_PENDING_PAYMENT', 'PENDING_PAYMENT')}
                startDate={policy?.startDate}
                endDate={policy?.endDate}
                origin=""
                pathwayType=""
                labels={[
                  policy?.commissionStatus,
                  policy?.isDeleted && 'DELETED',
                  policy?.exchangePolicyNumber,
                  policy?.insuranceSource,
                  !!policy.agentID
                    ? ( policy?.agentID === '20655395' || policy?.agentID === '18946575' )  // TODO remove this after OE for PY24
                      ? '✓ NPN'
                      : policy?.agentID === '17068737'
                      ? 'Stride'
                      : '✕ NPN'
                    : 'No NPN',
                ]}
                issuer={policy?.providerPlan?.issuer?.name || policy?.carrier}
                grossPremium={policy?.premium}
                netPremium={policy?.premiumEffective}
              />
            ))}
          </RowGroup>
        );

      })}
 
    </Scroll>
  );
};
