import React from 'react';
import { Status } from 'src/web/components';
import { gql } from '@apollo/client';
import { formatCurrency, format } from 'src/_utils';

export const GOAL_NAMES = {
  taxes: 'Taxes',
  timeoff: 'Time Off',
  retirement: 'Retirement',
  'health-expenses': 'Health Expenses',
  'family-leave': 'Family Leave',
  'emergency-savings': 'Emergency Savings',
  custom: 'Custom',
  DDA: 'DDA',
  IRA: 'IRA',
  TAX: 'Taxes',
  PTO: 'Time Off',
  RETIREMENT: 'Retirement',
  HEALTH_EXPENSES: 'Health Expenses',
  FAMILY_LEAVE: 'Family Leave',
  EMERGENCY_SAVINGS: 'Emergency Savings',
};

export const GOAL_FOR_FEE = {
  TAX_PAYMENT_FEE: 'TAX',
  PREMIUM_PAYMENT_FEE: 'HEALTH',
  RETIREMENT_FEE: 'RETIREMENT',
};

export const goalStatusColor = status => {
  return status === 'ACTIVE'
    ? 'nudgePeachColor'
    : status === 'DELETED'
    ? 'creditLightColor'
    : status === 'PAUSED'
    ? 'planColor'
    : 'skeletonColor';
};

export const goalRingColor = status => {
  return status === 'ACTIVE'
    ? 'debitColor'
    : status === 'DELETED'
    ? 'creditColor'
    : status === 'PAUSED'
    ? 'nudgePeachColor'
    : 'borderColor';
};

export const portfolioName = name => {
  if (!name) return '';
  else
    return name
      ?.replace('Conservative', 'Modest')
      ?.replace('Moderate', 'Balanced')
      ?.replace('Aggressive', 'Bold')
      ?.replace('ESG', ' ESG');
};

export const all = `
  id
  slug
  goalType
  createdOn
  status
  totalBalance
  availableBalance
  pendingBalance
  paycheckPercentage
`;

export const exclusions = ['id', '__typename', 'status'];

export const fieldTypes = {
  id: 'String',
  createdOn: 'Date',
  status: 'Status',
  paycheckPercentage: 'Percent',
  totalBalance: 'Currency',
  availableBalance: 'Currency',
  pendingBalance: 'Currency',
  isAccountReady: Boolean,
  totalLiability: 'Currency',
  estimatedPaycheckPercentage: 'Percent',
  grossIncome: 'Currency',
  numDependents: Number,
  state: 'String',
  year: 'String',
  socialSecurityLiability: 'Currency',
  medicareLiability: 'Currency',
  federalLiability: 'Currency',
  stateLiability: 'Currency',
  adjustedGrossIncome: 'Currency',
  childCredit: 'Currency',
  taxableIncome: 'Currency',
  filingStatus: 'String',
  spouseIncome: 'Currency',
  externalSavings: 'Currency',
  estimatedAnnualIncome: 'Currency',
  incomeContributions: 'Currency',
  totalContributions: 'Currency',
  portfolio: 'NameObj',
  plannedTarget: 'NumDays',
  unplannedTarget: 'NumDays',
  riskLevel: 'String',
  riskComfort: 'String',
};

export const toPercent = value => {
  return `${Math.round(value * 100)}%`;
};

export const toDays = value => {
  return `${value} days`;
};

export const toDate = value => {
  return format(new Date(value), 'MMMM dd, yyyy');
};

export const toObjectsName = value => {
  return value?.name;
};

export const toString = value => {
  return value.toProperCase();
};

export const toStatus = value => {
  return <Status status={value}>{value}</Status>;
};

export const formatters = {
  Currency: formatCurrency,
  Date: toDate,
  Percent: toPercent,
  NameObj: toObjectsName,
  NumDays: toDays,
  String: toString,
  Status: toStatus,
};

export const TAX_GOAL = gql`
  query TaxGoal($id: ID) {
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      taxGoal: goal(slug: "taxes") {
        id
        slug
        goalType
        createdOn
        status
        totalBalance
        availableBalance
        pendingBalance
        paycheckPercentage
        ... on TaxGoal {
          estimatedPaycheckPercentage
          numDependents
          totalLiability
          socialSecurityLiability
          medicareLiability
          federalLiability
          stateLiability
          adjustedGrossIncome
          childCredit
          taxableIncome
          filingStatus
          spouseIncome
        }
      }
    }
  }
`;

export const PTO_GOAL = gql`
  query TimeOff($id: ID) {
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      ptoGoal: goal(slug: "timeoff") {
        id
        slug
        goalType
        createdOn
        status
        totalBalance
        availableBalance
        pendingBalance
        paycheckPercentage
        ... on PTOGoal {
          plannedTarget
          unplannedTarget
          estimatedAnnualIncome
        }
      }
    }
  }
`;

export const RETIREMENT_GOAL = gql`
  query Retirement($id: ID) {
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      retirementGoal: goal(slug: "retirement") {
        id
        slug
        goalType
        createdOn
        status
        totalBalance
        availableBalance
        ... on RetirementGoal {
          settledBalance
          pendingBalance
          paycheckPercentage
          externalSavings
          estimatedAnnualIncome
          riskLevel
          riskComfort
          accountType
          filingStatus
          incomeContributions
          totalContributions(year: 2020)
          portfolio {
            id
            name
          }
          wealthAccount {
            id
            accountType
            balance
            growth
            totalContributions
            isPrimaryAccount
            isDisabled
            folioId: folioID
            accountId: accountID
            userId: userID
          }
        }
      }
    }
  }
`;

export const HEALTH_SAVINGS_GOAL = gql`
  query HealthSavings($id: ID) {
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      healthExpensesGoal: goal(slug: "health-expenses") {
        id
        slug
        goalType
        createdOn
        status
        totalBalance
        availableBalance
        pendingBalance
        paycheckPercentage
      }
    }
  }
`;

export const EMERGENCY_SAVINGS_GOAL = gql`
  query EmergencySavings($id: ID) {
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      emergencySavingsGoal: goal(slug: "emergency-savings") {
        id
        slug
        goalType
        createdOn
        status
        totalBalance
        availableBalance
        pendingBalance
        paycheckPercentage
      }
    }
  }
`;

export const FAMILY_LEAVE_GOAL = gql`
  query FamilyLeave($id: ID) {
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      familyLeaveGoal: goal(slug: "family-leave") {
        id
        slug
        goalType
        createdOn
        status
        totalBalance
        availableBalance
        pendingBalance
        paycheckPercentage
        ... on FamilyLeaveGoal {
          numDays
          endDate
        }
      }
    }
  }
`;

export const CUSTOM_SAVINGS = gql`
  query CustomGoal($id: ID, $slug: String) {
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      goal(slug: $slug) {
        id
        slug
        goalType
        createdOn
        status
        totalBalance
        availableBalance
        pendingBalance
        paycheckPercentage
        ... on CustomGoal {
          name
          icon
          target
          endDate
        }
      }
    }
  }
`;

export const DDA = gql`
  query DDAQuery($id: ID) {
    viewerTwo(id: $id) {
      id
      accounts {
        id
        providerType
        status
        accountNumber
        routingNumber
      }
    }
    viewer(id: $id) {
      id
      savingsAccountMetadata {
        savingsProviderType
      }
      savingsAccount {
        balance
        settledBalance
        status
        accountId: accountNumber
        id: providerUserId
        accountNumber
        routingNumber
        providerBaseAccountId
        kyc
        kycIdvRequired
      }
      user {
        id
        residency {
          isUSCitizen
          isGreenCardHolder
          birthCountry
          citizenshipCountry
          isResidentAlien
          visa {
            code
            expiry
          }
        }
      }
    }
  }
`;

export const DDA_OLD = gql`
  query BondQuery($id: ID) {
    viewerTwo(id: $id) {
      id
    }
  }
`;

export const IRA = gql`
  query IRAQuery($id: ID, $accountId: ID) {
    viewer(id: $id) {
      id
      retirementGoal: goal(slug: "retirement") {
        id
        ... on RetirementGoal {
          portfolio {
            id
            name
          }
        }
      }
      wealthAccountMetadata {
        kycStatus
      }
      wealthAccount(id: $accountId) {
        id
        accountType
        balance
        growth
        totalContributions
        isPrimaryAccount
        isDisabled
        folioId: folioID
        accountId: accountID
        userId: userID
      }
    }
  }
`;

export const QUERIES = {
  taxes: TAX_GOAL,
  timeoff: PTO_GOAL,
  retirement: RETIREMENT_GOAL,
  'health-expenses': HEALTH_SAVINGS_GOAL,
  'family-leave': FAMILY_LEAVE_GOAL,
  'emergency-savings': EMERGENCY_SAVINGS_GOAL,
  custom: CUSTOM_SAVINGS,
  DDA: DDA,
  DDA_OLD: DDA_OLD,
  IRA: IRA,
};

export const NAMES = {
  taxes: 'taxGoal',
  timeoff: 'ptoGoal',
  retirement: 'retirementGoal',
  'health-expenses': 'healthExpensesGoal',
  'family-leave': 'familyLeaveGoal',
  'emergency-savings': 'emergencySavingsGoal',
  DDA: 'savingsAccount',
  IRA: 'wealthAccount',
};

export const formatter = ({ value, type }) => {
  if (formatters[type]) return formatters[type](value);
  else return value;
};

const icons = {
  TAX_SAVINGS: 'tax',
  RETIREMENT: 'retirement',
  PTO: 'time-off',
  EMERGENCY_SAVINGS: 'emergency-savings',
  HEALTH_EXPENSES: 'health-expenses',
  FAMILY_LEAVE: 'family-leave',
  CAR: 'car',
  HOUSE: 'house',
  TARGET: 'target',
  LAPTOP: 'laptop',
  GIFT: 'gift',
  LUGGAGE: 'luggage',
  CAKE: 'cake',
  TOOLS: 'tools',
  HEALTH: 'health',
  DENTAL: 'dental',
};

const colors = {
  TAX_SAVINGS: 'var(--c-orange-1)',
  RETIREMENT: 'var(--c-teal-1)',
  PTO: 'var(--c-lime-1)',
  FALLBACK: 'var(--c-lime-1)',
};

export const goalIcon = ({ type, alt }) => {
  return `/imgNew/${alt ? 'alt/' : ''}${icons[type] || 'savings'}.png`;
};

export const goalColor = ({ type }) => {
  return colors[type] || colors.FALLBACK;
};

const weights = {
  progress: {
    ACTIVE: 5,
    PAUSED: 4,
    GOAL_MET: 4,
    DELETED: 1,
    PENDING: 2,
    COMPLETE: 2,
    IN_PROGRESS: 2,
    NOT_STARTED: 2,
    CANCELED: 1,
    DRAFT: 2,
  },
  importance: {
    VITAL: 4,
    IMPORTANT: 3,
    CONSIDER: 2,
    NONE: 1,
    COVERED: 1,
    CONTRIBUTING: 1,
  },
  type: {
    TAX: 7,
    RETIREMENT: 6,
    PTO: 5,
    HEALTH_EXPENSES: 4,
    FAMILY_LEAVE: 3,
    EMERGENCY_SAVINGS: 2,
    HEALTH: 1,
    CUSTOM_SAVINGS: 0,
  },
};

// Helper function to grab the weight
const progress = benefit => weights.progress[benefit?.progress] || 0;
const status = benefit => weights.progress[benefit?.status] || 0;
const percent = benefit => benefit?.paycheckPercentage || 0;
const importance = benefit => weights.importance[benefit?.importance] || 0;
const type = benefit => weights.type[benefit?.type] || 0;

/**
 * Function to help sort todo benefit cards
 * - First, prioritizes by whether in progress or not
 * - Second, prioritizes by importance level
 * - Last, defaults to the benefit order
 */
export const sortBenefits = (a, b) => {
  if (progress(a) === progress(b)) {
    if (importance(a) === importance(b)) {
      return type(a) > type(b) ? -1 : 1;
    } else {
      return importance(a) > importance(b) ? -1 : 1;
    }
  } else {
    return progress(a) > progress(b) ? -1 : 1;
  }
};

export const sortActiveBenefits = (a, b) => {
  if (status(a) === status(b)) {
    if (percent(a) === percent(b)) {
      return type(a) > type(b) ? -1 : 1;
    } else {
      return percent(a) > percent(b) ? -1 : 1;
    }
  } else {
    return status(a) > status(b) ? -1 : 1;
  }
};
